import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router'
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { NavigationService } from 'src/app/admin-mod/services/navigation.service';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
  url_activated: string;
  constructor(private _router: Router, private _auth: AuthService, private navigationService:NavigationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentUser = this._auth.currentUserValue;

    if (currentUser) {
      // logged in so return true
      if (state.url === '/login') {
        this._router.navigate(['/home/overview']);
      }
      return true;
    }

    this._router.navigate(['/login']
      //   ,{queryParams: {returnUrl:state.url}}
    );
    return false;
  }
  // canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   const allowedRoles = next.data.allowedRoles;
  //   this.url_activated = this._router.url
  //   // const isAuthorized = this.navigationService.isAuthorized(allowedRoles);
  //   const isAuthorized = this.authorizationService.isAuthorized(allowedRoles);

  //   if (!isAuthorized) {
  //     this._router.navigate(['accessdenied']);
  //   }

  //   return isAuthorized
  // }
}