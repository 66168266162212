import { Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { ENVIRONMENT, API } from "../../common/restapi";
import { throwError, BehaviorSubject } from "rxjs";
import Swal from 'sweetalert2';

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}

@Injectable({ providedIn: "root" })

export class AuthService {
  localIp = sessionStorage.getItem('LOCAL_IP');
  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(localStorage.getItem("currentUser"));
  public currentUser = this.currentUserSubject.asObservable();
  authToken: string;
  status_data: any;
  headers: HttpHeaders;
  deviceInfo = null;
  userDetails: any = {};

  constructor(private http: HttpClient, private _router: Router, private zone: NgZone) {}

  getAuthorizationToken() {
    this.authToken = localStorage.getItem("currentUser");
    return this.authToken;
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log("An error occurred:", error.error.message);
    }
    Swal.fire({
      title: 'Error code 500!',
      text: 'An error occurred, server not responsed',
      icon: 'error',
      confirmButtonText: 'Ok'
    })
    return throwError({
      status: error.status,
      error: error.error,
      message: "API REQUEST FAILED. PLEASE TRY AFTER SOMETIME."
    });
  }

  // Local IP Integration Start
  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);


  login(form: any) {

    const payload = {
      username: form.value.username,
      password: form.value.password,
      // password: crypto.sha512(form.value.password),
    }
    console.log("Payload for Login :: ", payload);

    return this.http.post<any>(`${ENVIRONMENT.BASEURL}${API.SIGNIN}`, payload)
      .pipe(map(user => {
        if (user.code == "200") {
          this.userDetails = user.result;
          // login successful if there's a jwt token in the response
          console.log("user details is", this.userDetails );
          // if (user) {
          if (this.userDetails.token != undefined) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', window.btoa(window.btoa(window.btoa(window.btoa(JSON.stringify(this.userDetails))))));
            this.currentUserSubject.next(this.userDetails);
          } else {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', window.btoa(window.btoa(window.btoa(window.btoa(JSON.stringify(this.userDetails))))));
            // localStorage.setItem('currentUser', JSON.stringify(this.userDetails));
            this.currentUserSubject.next(null);
          }
          // }
          return user;
        } else { 
          return user;
        }
      }));
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  logout() {

    this.currentUserSubject.next(null);
    localStorage.clear();
    this._router.navigateByUrl("/login");


    // this.headers = this.setHeader();
    // let logoutPayload = {
    //   Ip: sessionStorage.getItem('LOCAL_IP'),
    //   browserName: "chrome",
    //   loginSession: "logout"
    // };
    // return this.http.post<any>(`${ENVIRONMENT.BASEURL}${API.LOGOUT}`, logoutPayload, { headers: this.headers })
    //   .pipe(
    //     map(res => {
    //       this.currentUserSubject.next(null);
    //       localStorage.clear();
    //       this._router.navigateByUrl("/login");
    //     },
    //       catchError(this.handleError)
    //     ));
  }

  // HTTP Request and Response Code
  checkStatus(code: string) {
    // alert(code);
    if (code == "200") {
      this.status_data = {
        message: "OK",
        errorcode: 200
      };
    } else if (code == "400") {
      this.status_data = {
        message: "Bad Request",
        errorcode: 400
      };
    } else if (code == "401") {
      this.status_data = {
        message: "Unauthorized",
        errorcode: 401
      };
      this.logout();
      return this.status_data;
    } else if (code == "403") {
      this.status_data = {
        message: "Forbidden",
        errorcode: 403
      };
    } else if (code == "404") {
      this.status_data = {
        message: "Not Found",
        errorcode: 404
      };
    } else if (code == "406") {
      this.status_data = {
        message: "Wrong Credentials",
        errorcode: 406
      };
    } else if (code == "500") {
      this.status_data = {
        message: "Internal Server Error",
        errorcode: 500
      };
    } else if (code == "201") {
      this.status_data = {
        message: "Created",
        errorcode: 201
      };
    } else if (code == "409") {
      this.status_data = {
        message: "conflict",
        errorcode: 409
      };
    }
    return this.status_data;
  }

  getCurrentUser() {
    if (localStorage.getItem("currentUser")) {
      return (this.currentUser = JSON.parse(
        window.atob(
          window.atob(
            window.atob(window.atob(localStorage.getItem("currentUser")))
          )
        )
      ));
    }
  }

  setHeader() {
    if (localStorage.getItem("currentUser")) {
      return (this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")//changes made on 13-11
        // .set("x-auth-token", this.getCurrentUser().token)
      );
    }
  }
  setHeaders() {
    if (localStorage.getItem("currentUser")) {
      return (this.headers = new HttpHeaders()
        .set("Content-Type", "application/json")//changes made on 13-11
        .set("x-auth-token", this.getCurrentUser().token)
      );
    }
  }
  setHeadersMultiPart() {
    if (localStorage.getItem("currentUser")) {
      return (this.headers = new HttpHeaders()
        .set("Content-Type", "multipart/form-data")
        .set("x-auth-token", this.getCurrentUser().token)//changes made on 13-11
      );
    }
  }

  setHeadersMulti() {
    if (localStorage.getItem("currentUser")) {
      return (this.headers = new HttpHeaders()
        .set("Content-Type", "multipart/form-data")//changes made on 13-11
      );
    }
  }

  public showToster(type: any, message: any) { 
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 1900,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })    
    Toast.fire({
      icon: type,
      title: message
    })
  }


}
