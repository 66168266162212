import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { API, ENVIRONMENT } from "../../common/restapi";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../../http-interceptors/authentication/auth.service";
import Swal from 'sweetalert2';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Injectable({ providedIn: "root" })



@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private baseUrl: string;
  headers: HttpHeaders;
  apiData: any = [];
  userData: any;
  apiResult: any;
  apiresultMEssage: string;
  menuList: any = [];
  permission: any;
  parms: any = "";
  adminPermission: any = {};
  listingPermission: any = {};
  sysSetPermission: any = {};
  homePermission: any = {};
  modePermission: any = {};
  mainMenuName: any;
  constructor(private http: HttpClient, private authService: AuthService, private permissionsService: NgxPermissionsService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.baseUrl = ENVIRONMENT.BASEURL;

  }

  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }



  // for navigation api start
  leftNavigation(data: any): Observable<any> {
    // console.log(this.headers);
    return this.http.post(this.baseUrl + `${API.LEFT_NAVIGATION}`, data).pipe(
      map(res => res),
      catchError(this.authService.handleError)
    );
  }
  // for navigation api end


  getMenulist() {
    this.userData = this.authService.getCurrentUser();
    let payload = {
      usertype: this.userData.usertype,
      roles: this.userData.roles
    }
    this.leftNavigation(payload).subscribe(result => {
      this.apiResult = result; console.log(result)

      this.apiresultMEssage = this.apiResult.resultmsg[0].msgdescription;
      if (this.apiResult.code == "200") {
        this.apiData = result.result;
        this.menuList = this.apiData[0].menuList;
        // console.log("userid on login", this.menuList);

        for (let i = 0; i < this.menuList.length; i++) {
          this.mainMenuName = this.menuList[i].menuName;
          if (this.mainMenuName == "Home") {
            this.homePermission = this.menuList[0].permission[0];
          }
          if (this.mainMenuName == "Administrator") {
            this.adminPermission = this.menuList[0].permission[0];
          }
          if (this.mainMenuName == "Listing") {
            this.listingPermission = this.menuList[0].permission[0];
          }
          if (this.mainMenuName == "Moderation") {
            this.modePermission = this.menuList[0].permission[0];
          }
          if (this.mainMenuName == "System Settings") {
            this.sysSetPermission = this.menuList[0].permission[0];
          }
        }
        // console.log("homePermission permission is", JSON.stringify(this.homePermission));
        // console.log("adminPermission permission is", this.adminPermission);
        // console.log("listingPermission permission is", this.listingPermission);
        // console.log("modePermission permission is", this.modePermission);
        // console.log("sysSetPermission permission is", this.sysSetPermission);

        // this.getpermission(this.apiData);
        //this.authService.showToster("success", this.apiresultMEssage);
      } else {
        this.authService.showToster("error", this.apiresultMEssage);
        //Swal.fire('Error', this.apiresultMEssage);
      }
    });
  }

  // getpermission(val: any) {
  //   let dataRes = val;
  //   this.menuList = this.apiData[0].menuList;
  //   console.log("userid on login", this.menuList);


  //   for (let i = 0; i <= this.menuList.length; i++) {
  //     this.mainMenuName = this.menuList[i].menuName;

  //     if (this.mainMenuName == "Home") {
  //       this.homePermission = this.menuList[0].permission[0];
  //     }
  //     if (this.mainMenuName == "Administrator") {
  //       this.adminPermission = this.menuList[0].permission[0];
  //     }
  //     if (this.mainMenuName == "Listing") {
  //       this.listingPermission = this.menuList[0].permission[0];
  //     }
  //     if (this.mainMenuName == "Moderation") {
  //       this.modePermission = this.menuList[0].permission[0];
  //     }
  //     if (this.mainMenuName == "System Settings") {
  //       this.sysSetPermission = this.menuList[0].permission[0];
  //     }
  //     console.log("adminPermission permission is", this.adminPermission);
  //     console.log("listingPermission permission is", this.listingPermission);
  //     console.log("modePermission permission is", this.modePermission);
  //     console.log("sysSetPermission permission is", this.sysSetPermission);
  //   }
  // }

  // isAuthorized() {

  //     const perm = ["administration/edit", "administration/add"];
  //     this.permissionsService.loadPermissions(perm);

  //      this.http.get('administration/add').subscribe((permissions) => {
  //        //const perm = ["ADMIN", "EDITOR"]; example of permissions
  //       //  this.permissionsService.loadPermissions(permissions);
  //     })


  // for (let i = 0; i < this.data.length; i++) {
  //   this.excelDownloadArray.push({
  //     Agent_Name: this.data[i]["humanAgentname"],
  //     Customer_Name: this.data[i]["customerName"],
  //     Channel: this.data[i]["channel"],
  //     Date_Time: this.data[i]["timeStamp"],
  //     Conversation_Id: this.data[i]["conversatationId"],
  //     Model: this.data[i]["model"],
  //     Type: this.data[i]["type"],
  //     Remarks: this.data[i]["remarks"],
  //   });
  // }
  // }



}

