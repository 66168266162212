import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Component({
  selector: 'app-loader',
  template: `<ngx-loading-bar [color]="'#4092F1'" [height]="'4px'"></ngx-loading-bar>`,
  styles: []
})
export class LoaderComponent implements OnInit, AfterViewInit {

  constructor(private loadingBar: LoadingBarService) {
    this.startLoading();
  }

  ngOnInit() {

  }

  startLoading() {
    this.loadingBar.start();
  }

  stopLoading() {
    this.loadingBar.complete();
  }

  ngAfterViewInit() {
    this.stopLoading();
  }

  emitStart() {
    this.loadingBar.start();
  }

  emitStop() {
    this.loadingBar.stop();
  }

  emitReset() {
    // this.loadingBar.reset();
  }

  emitComplete() {
    this.loadingBar.complete();
  }

}
