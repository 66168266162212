import { Component } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'saveMax';

  constructor(private permissionsService: NgxPermissionsService,
               private http: HttpClient) {}
 
  ngOnInit(): void {
    // const perm = ["http://localhost:4200/administration/list", "administration/add"];
    // this.permissionsService.loadPermissions(perm);
  }
}
