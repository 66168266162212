export const API = {
  SIGNIN: "admin/signin",
  SETPASSWORD: "setpassword",
  PASSWORD_LINK: "setPassword/{token}",
  DEFINE_ROLL: "getroll",

  FORGOT: "admin/forgotassword",
  SET_PASSWORD_link: "admin/setpassword",


  LOGIN: "botReport/report/login",
  RESET: "botReport/report/reset",
  LOGOUT: "botReport/report/logout",

  // administrator and moderator with their settings apis start
  ADD_ADMINISTRATOR: "admin/signup",
  EDIT_ADMINISTRATOR: "admin/edituser",
  DELETE_ADMINISTRATOR: "admin/delteuser",
  BLOCK_ADMINISTRATOR: "admin/blockuser",
  ACTIVE_ADMINISTRATOR: "admin/activeuser",
  // LIST_ADMINISTRATOR: "admin/getadminmodraterlist",
  LIST_ADMINISTRATOR: "admin/getadminmodraterlistnew",
  GET_ROLL: "admin/getlistofroll",
  SET_PASSWORD: "admin/updatepasswordsetting",
  GET_PASSWORD_SETTING: "admin/getpasswordsetting",
  CHANGE_PASSWORD: "admin/changePassword",
  // administrator and moderator with their settings apis end

  // mlsListing api start
  // MLS_LISTING: "mls/getmlsdata",
  MLS_LISTING: "mls/getmlsdatanew",
  MLS_CONFIG: "admin/getconfigrationlist",

  GET_FILTERED_DATA: "mls/getfilterdata",

  // navigarion Api Start
  LEFT_NAVIGATION: "admin/getroll",

  // navigarion Api End

  // Blogs with their settings apis start
  LIST_BLOG: "blog/getBloglistNew",
  ADD_BLOG: "blog/createblog",
  TAGS_lIST: "/gettags",


  // Blogs with their settings apis end

  GET_CATEGORY_LIST: "blog/getcategorylist",
  PUBLISH_BLOG: "blog/createblog",
  EDIT_PUBLISH_BLOG: "blog/editblog",
  DELETE_BLOG: "blog/delteblog",

  //for upcoming properties
  ADD_PROPERTY: "property/addproperty",
  EDIT_PROPERTY: "property/editproperty",
  DEL_PROPERTY: "property/delteproperty",
  ACTIVE_PROPERTY: "property/activeproperty",
  LIST_PROPERTY: "property/getupcomingpropertylistNew",
  IMAGE_PROPERTY: "property/propertyimageupload",
  GET_HOME_LIST: "/gettypeofhome",
  GET_PROVINCE_LIST: "/gettypelocation",
  GET_SUB_LOCATION: "/getsublocation",
  IMAGE_PROPERTY_MULTIPART: "uploadMultipleFiles",
  DELETE_IMAGE: "deleteFile",

  // https://savemaxapi.oneclick.info/searchservice/uploadMultipleFiles

  //Moderation Listing Module
  LIST_MODERATION: "/freelease/getfreeleaseList",
  CHANGE_STATUS: "/freelease/aprovefreelisting",
  CHANGE_STATUS_NEW: "/freelease/createPropertyOnApprove",
  UPDATE_STATUS: "/freelease/updateExclusive",
  CHANGE_STATUS_ALL: "/freelease/bulkaprovefreelisting",


  // ! Leads Listing Module
  LIST_NEIGHBOURHOOD: "/getneighbourhood",
  ALL_LIST_NEIGHBOURHOOD: "/getneighbourhoodLeads",
  LIST_LIST_YOUR_HOME: "/savehome/getAllSaveHome",
  LIST_FIRST_TIME_HOME_BUYERS: "mls/getFirstTimeBuyerListCms",
  
  EXPORT_EXCEL: "/download/Neighbourhood.xlsx",
  LIST_HOME_INTEREST: "/getgethome",
  HOME_INTEREST_EXPORTS_EXCEL: "/getHomeWorthLeads",
  HOME_INTEREST_EXPORT_EXCEL: "/download/homeworth.xlsx",
  PROPERTY_VISTI_INTEREST_LIST: "/mls/getpropertlivistList",
  PROPERTY_VISTI_INTEREST_ALL_LIST: "/mls/propertyVisitleads",
  PROPERTY_VISTI_INTEREST_EXPORT_EXCEL: "/download/propinterest.xlsx",
  PROPERTY_GET_BEDS: "/getbads",
  PROPERTY_GET_GARAGE: "/getgarage",
  PROPERTY_GET_BATH: "/getbath",
  LIST_OF_COMUNITY: "community/getcommunity",
  LIST_BLOG_LEADS: "/blog/list",
  LIST_FRANCHISE_LEADS: "/franchise/list/",
  LIST_CONTACT_LEADS: "/contactus/list",
  LIST_HIRING_LEADS: "/hiring/list",

  // home Api Graphs Start

  HOME_DAYS: "home/homedays",
  HOME_DATA: "home/homedata",
  // home Api Graphs End
  HOME_DATA_INTERESTED: "home/homedatainterested",
  APP_CONFIG_LIST: "blog/listconfig",
  APP_CONFIG_CREATE: "blog/createconfig",
  APP_CONFIG_EDIT: "blog/editconfig",
  SEARCH_BLOG: "blog/getBlogsearch",
  SOCIAL_SHARING: "/admin/updatesharing",
  LIST_SOCIAL_SHARING: "/admin/getsharing",
  MODERATION_SETTINGS: "/admin/modratorsetting",
  EDIT_LANGUAGE: "/admin/editlanguage",
  CATEGORY_RULE: "/categoryrules/createcategoryrules",
  CREATE_WEB_CONTENT: "webcontentcontroller/creatwebcontent",
  GET_WEB_CONTENT: "webcontentcontroller/getwebconten",
  UPDATE_WEB_CONTENT: "webcontentcontroller/editwebcontent",
  CATEGORY_RULE_LIST: "/categoryrules/getcategoryruleslist",
  CATEGORY_DELETE: "/categoryrules/deletecategoryrules",
  EDIT_CATEGORY: "/categoryrules/getcategoryrules",
  EDIT_CATEGORYRULES: "/categoryrules/editcategoryrules",
  HOMEFILTER: "filter/homefilter",
  HOME_WORTH_FILTER: "filter/homefilter",
  HOMEFILTER_LIST: "filter/homefilterList",
  HOME_WORTH_FILTER_LIST_IS: "filter/homeWarthFilterList",
  HOMEWorth_FILTER_IS: "filter/homeWarthFilter",
  SCHEDULE_SYNK_DATA_ADD : "/schedul/createScheduling",
  SCHEDULE_SYNK_DATA_LIST: "/schedul/listSchedulingData",
  GET_ARCHIVE_ON_OFF_FEATURE: "archive/getArchiveOnOffFeature",
  ADD_CAL_WORTH_HOME: "/homewarth/savehomewarthalog",
  PUBLISH_CAL_WORTH_HOME: "/homewarth/getpublishedhomewarth",
  PUBLISH_CAL_WORTH_HOME_ALGO: "/homewarth/publishedHomeAlgo",
  GET_CAL_WORTH_HOME: "configs/gethomewarth",
  GET_TEAM_DESC: "dynamicteam/getTeamDescription",
  UPDATE_TEAM_DESC_IMG: "dynamicteam/saveTeamImage",
  UPDATE_TEAM_DESC: "dynamicteam/updateTeamDescription",
  GET_TEAM_LEADERS: "dynamicteam/allLeadership",
  GET_TEAM_LEADERS_SINGLE: "dynamicteam/getTeamLeader",
  UPDATE_TEAM_LEADERS_SINGLE: "dynamicteam/updateLeadership",
  ADD_TEAM_LEADERS_SINGLE: "dynamicteam/addLeadership",
  DELETE_TEAM_LEADERS_SINGLE: "dynamicteam/deleteLeadership",
  ALL_FRANCHISE: "dynamicteam/allFranchise",
  VIEW_FRANCHISE_DETAIL: "dynamicteam/viewFranchiseDetails",
  GET_SINGLE_FRANCHISE_CARD: "dynamicteam/getFranchise",
  ADD_SINGLE_FRANCHISE_CARD: "dynamicteam/addSavemaxFranchise",
  UPDATE_SINGLE_FRANCHISE_CARD: "dynamicteam/updateSavemaxFranchise",
  DELETE_SINGLE_FRANCHISE_CARD: "dynamicteam/deleteSavemaxFranchise",
  UPDATE_FRANCHISE_CARD_DETAIL: "dynamicteam/updateFranchiseDetails",
  ADD_FRANCHISE_CARD_DETAIL: "dynamicteam/addFranchiseDetails",
  ADD_FRANCHISE_REALTOR_DETAIL: "dynamicteam/addFranchiseDetailsRealtor",
  ADD_FRANCHISE_TEAM_LEAD_DETAIL: "dynamicteam/addFranchiseDetailsTeamLead",
  GET_SINGLE_FRANCHISE_REALTOR_CARD: "dynamicteam/viewFranchiseDetailsRealtor",
  UPDATE_FRANCHISE_REALTOR_DETAIL: "dynamicteam/updateFranchiseDetailsRealtor",
  GET_SINGLE_FRANCHISE_TL_DETAIL: "dynamicteam/viewFranchiseDetailsTeamLead",
  UPDATE_FRANCHISE_TL_DETAIL: "dynamicteam/updateFranchiseDetailsTeamLead",
  DELETE_FRANCHISE_REALTOR_DETAIL: "dynamicteam/deleteFranchiseDetailsRealtor",
  DELETE_FRANCHISE_TEAMLEAD_DETAIL: "dynamicteam/deleteFranchiseDetailsTeamLead",
  GET_ALL_PROVINCE_LIST: "dynamicteam/allsavemaxFranchiseProvince",
  GET_ALL_CITY_LIST: "dynamicteam/allsavemaxFranchiseCity",
  ADD_NEW_CITY: "dynamicteam/addProvinceCity",
  ADD_NEW_CITY_CARD: "dynamicteam/addSavemaxFranchise",
  DELETE_CITY_CARD: "dynamicteam/deleteProvinceCity",


  LIST_OF_MUNCIPALITY: "/meta/getmuncipality", //BASEURL_LOCAL
  GET_KEYWORDS: "/meta/getkeywards", //BASEURL_LOCAL
  SAVE_META_DATA: "/meta/savemetadata", //BASEURL_LOCAL
  LIST_META_DATA: "/meta/viewmetalist", //BASEURL_LOCAL
  EDIT_META_DATA: "/meta/viewmetadata", //BASEURL_LOCAL
  DELETE_META_DATA: "/meta/deletemetadata", //BASEURL_LOCAL

  SAVE_NAME_SEARCH_URL:"/mls/saveNameSearchUrl", //BASEURL + for Add saveSearchFileName
  LIST_SAVE_NAME_SEARCH_URL:"/mls/fetchNameUrlList", //BASEURL + for list saveSearchFileName
  DELETE_SAVE_NAME_SEARCH_URL:"/mls/deleteNameUrl", //BASEURL + for delete saveSearchFileName


 GET_CLASS_TYPE: "searchservice/search/getClassType", 
 GET_FILTER_DATA: "searchservice/search/getfilterdata", 
 GET_SEARCH_PLACE: "searchservice/search/searchPlace", 
 GET_SEARCH_SUGGESTION: "searchservice/search/searchsuggestion", 


 VIEW_DETAILS:"admin/viewAgentDetails",
 UPDATE_LIST:"admin/updateAgentId",
 ADD_REALTOR:"admin/assignAgentId",
 REALTOR_LIST:"admin/getAgentList",
 FRANCHISE_LIST:"franchise/franchiseList",
 REAL_EVENTS_LIST:"events/allEvent",
 ADD_REAL_EVENTS:"events/addEvent",
 GET_DETAILS_REAL_EVENTS:"events/viewEvent",
 UPDATE_REAL_EVENTS:"events/updateEvent",
 DELETE_REAL_EVENTS:"events/deleteEvent",

 REQ_FORM_LIST:"form/allForm",
 ADD_REQ_FORM:"form/addForm",
 GET_DETAILS_REQ_FORM:"form/viewForm",
 UPDATE_REQ_FORM:"form/updateForm",
 DELETE_REQ_FORM:"form/deleteForm",
 UPDATE_REQ_FORM_STATUS:"form/updateFormStatus",

 LIST_UTM:"/getAllUtmList",
 ADD_UTM:"/saveUtm",
 ACTIVATION_UTM:"/inActiveUtm",
 EDIT_UTM:"/editUtm",
 VIEW_UTM:"/viewUtm",

 ALL_WEB_USERS : '/getAllWebUser',
 ADD_USER : 'signupAgent',
 EDIT_USER : 'editAgent',
 VIEW_USER : 'viewAgent',
 CREATE_MULTI_AGENTS: 'createMultipleAgent',
 GET_COUNTRY_CODE: "configs/getCountryCodeList",
 
 GET_MORTGAGE_CALCULATION_LEADS: "cms/leads/cmsmortgageCalLead",
 GET_POST_LANDING_LEADS: "cms/leads/cmsPostLandingLead",
  // https://uatapi.savemax.com/api
};

export const ENVIRONMENT = {
  DEV: true,

  
  // NEW_BASEURL:"https://uatapi.savemax.com/",
  // BASEURL: "https://uatapi.savemax.com/api/",
  // BASEURL_USER: "https://uatapi.savemax.com/api/user",
  // BASEURL_ADMIN: "https://uatapi.savemax.com/api/admin",
  // BASEURL_IMAGE: "https://uatapi.savemax.com/api/searchservice/",
  // BASEURL_UPLOAD_IMAGE: "https://uatapi.savemax.com/api/searchservice/",
  // BASEURL_LOCAL: "https://uatapi.savemax.com/api",
  // BASEURL_USER_SERVICE: "https://uatapi.savemax.com/api/user/",
  // BASEURL_USER_SERVICE1: "https://uatapi.savemax.com/userservice/api/user/",
  // BASEURL_USER_SERVICE2: "https://uatapi.savemax.com/userservice/api/",
  // BASEURL_AUTH_SERVICE: "https://uatapi.savemax.com:8081/authentication/",


  // UAT SaveMAx Cms Base Url End



  // Production base urls start
  NEW_BASEURL:"https://api2.savemax.com/",
  BASEURL: "https://api2.savemax.com/api/",
  BASEURL_USER: "https://api2.savemax.com/api/user",
  BASEURL_ADMIN: "https://api2.savemax.com/api/admin",
  BASEURL_IMAGE: "https://api2.savemax.com/searchservice/",
  BASEURL_UPLOAD_IMAGE: "https://api2.savemax.com/imageservice/",
  BASEURL_LOCAL: "https://api2.savemax.com/api/",
  BASEURL_USER_SERVICE: "https://api2.savemax.com/userservice/api/user/",
  BASEURL_USER_SERVICE1: "https://api2.savemax.com/userservice/api/user/",
  BASEURL_USER_SERVICE2: "https://api2.savemax.com/userservice/api/",
  BASEURL_AUTH_SERVICE: "https://api2.savemax.com:9091/authentication/",

   // Production base urls End



}
