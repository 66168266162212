import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';




import { LoaderComponent } from './loader/loader.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxPaginationModule } from 'ngx-pagination';
import { CKEditorModule } from 'ng2-ckeditor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AgmCoreModule } from '@agm/core';
import { ClickOutsideModule } from 'ng-click-outside';


@NgModule({
  declarations: [LoaderComponent,
    // FilterPipe, SearchPipe, SearchPhrasePipe, DropdownPipe, userSearchPipe, groupSearchPipe, IntentPipe, SafePipe, TitleCasePipe, FilterGroupPipe
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NgxPermissionsModule,
    NgxPaginationModule,
    CKEditorModule,
    BsDatepickerModule,
    ClickOutsideModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC686X49eO5SmTXlaNMUOdrWwDzC6UmJZ0',
      libraries: ["places", "geometry"]
    }),
    
    // DataTableModule,
    // NgbModule,
    // SlickCarouselModule

  ],
  exports: [
    LoaderComponent,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NgxPermissionsModule,
    NgxPaginationModule,
    CKEditorModule,
    BsDatepickerModule,
    ClickOutsideModule,
    AgmCoreModule,
    
    // DataTableModule,
    // FilterPipe,
    // SearchPipe,
    // IntentPipe,
    // NgbModule,
    // SearchPhrasePipe,
    // DropdownPipe, userSearchPipe, groupSearchPipe,
    // SafePipe, TitleCasePipe,FilterGroupPipe,
    // SlickCarouselModule

  ]
})
export class SharedModule { }
